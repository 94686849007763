import React from 'react';
import './App.css';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, LedgerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, Box, Typography, Link, Grid } from '@mui/joy';
import WalletCleaner from './components/wallet-cleaner/WalletCleaner';
import About from './components/About';

const queryClient = new QueryClient();

const App = () => {
    const wallets = [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter()
    ];
    const endpoint = "https://mainnet.helius-rpc.com/?api-key=bea1fbe7-1382-405b-9e45-0345c35403f8";

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <QueryClientProvider client={queryClient}>
                        <CssBaseline />
                        <Box sx={{ bgcolor: '#050505', minHeight: '100vh', p: 4, fontFamily: 'F37 Atlantic, sans-serif' }}>
                            <Grid container spacing={2} sx={{ bgcolor: '#000000', p: 2, borderRadius: 1, mb: 4 }}>
                                {/* Logo and Title */}
                                <Grid item xs={12} sm={4}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <a href="https://claimer.fun" target="_blank" rel="noopener noreferrer">
                                            <img src='/claimer-duck.png' alt="Claimer Logo" style={{ width: 40, height: 40 }} />
                                        </a>
                                        <Typography level="h3" sx={{ fontWeight: 'bold', color: '#FFFFFF', fontFamily: 'F37 Atlantic, sans-serif', fontSize: { xs: '0', lg: '2.5rem' } }}>
                                            CLAIMER.FUN
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* Navigation Links */}
                                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 4, alignItems: 'center' }}>
                                    <Link href="#about" underline="none">
                                        <Typography level="h5" sx={{ color: '#FFFFFF', cursor: 'pointer', fontWeight: 'bold', fontFamily: 'F37 Atlantic, sans-serif', fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                                            ABOUT
                                        </Typography>
                                    </Link>
                                    <Link href="#features" underline="none">
                                        <Typography level="h5" sx={{ color: '#FFFFFF', cursor: 'pointer', fontWeight: 'bold', fontFamily: 'F37 Atlantic, sans-serif', fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                                            FEATURES
                                        </Typography>
                                    </Link>
                                </Grid>
                                {/* Wallet Button */}
                                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                                    <WalletMultiButton />
                                </Grid>
                            </Grid>
                            <WalletCleaner />
                            <About />
                        </Box>
                    </QueryClientProvider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;
