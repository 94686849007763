import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import FavoriteIcon from '@mui/icons-material/Favorite';

const About = () => {
    return (
        <Box sx={{
            bgcolor: '#000',
            color: '#FFFFFF',
            p: 5,
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Typography id="about" variant="h1" sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'white',
                mb: 3,
                fontFamily: 'F37 Atlantic, sans-serif',
                fontSize: {
                    xs: '2.5rem',
                    sm: '4rem',
                    md: '6rem'
                }
            }}>
                RECLAIM, OPTIMIZE, EARN
            </Typography>
            <Typography variant="body1" sx={{
                color: '#8A2BE2',
                textAlign: 'center',
                mb: 2,
                fontSize: '1rem',
                fontFamily: 'F37 Atlantic, sans-serif'
            }}>
                CREATED WITH <FavoriteIcon fontSize="small" sx={{ color: 'red', ml: 1 }} />
            </Typography>
            <Typography variant="h4" sx={{
                textAlign: 'center',
                mb: 3,
                fontWeight: 'bold',
                color: 'white',
                fontFamily: 'F37 Atlantic, sans-serif'
            }}>
                ABOUT
            </Typography>
            <Typography variant="h3" sx={{
                color: 'white',
                textAlign: 'center',
                maxWidth: 800,
                fontSize: '1rem',
                lineHeight: 1.5,
                mb: 40,
                fontFamily: 'F37 Atlantic, sans-serif'
            }}>
                EFFORTLESSLY CLOSE UNWANTED TOKEN ACCOUNTS, RECLAIM RENT FEES, AND MAXIMIZE PROFITS ACROSS MULTIPLE WALLETS. SWAP TOKENS BEFORE BURNING FOR OPTIMAL RETURNS AND EVEN CLAIM SOL FROM ZERO-BALANCE ACCOUNTS.
            </Typography>

            <Typography id="features" variant="h1" sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'white',
                mb: 3,
                fontSize: {
                    xs: '2.5rem',
                    sm: '4rem',
                    md: '6rem'
                },
                fontFamily: 'F37 Atlantic, sans-serif'
            }}>
                FUNCTIONALITY
            </Typography>
            <Grid container spacing={3} sx={{ maxWidth: 1200, mt: 2 }}>
                {[{
                    id: 1,
                    title: 'ACCOUNT MANAGER',
                    description: 'CONVENIENT MAINTENANCE OF THE FARM OF ALL WALLETS IN ENCRYPTED FORM'
                }, {
                    id: 2,
                    title: 'MODULES/SIMPLE MODE',
                    description: 'SINGLE MODULES THAT YOU CAN RUN ON YOUR ENTIRE FARM'
                }, {
                    id: 3,
                    title: 'MODULES/TRACKS',
                    description: 'SEVERAL MODULES THAT ARE EXECUTED ONE AFTER ANOTHER'
                }, {
                    id: 4,
                    title: 'EXCHANGE MANAGER',
                    description: 'MAINTAINING AND TRACKING COIN BALANCES ON ALL YOUR EXCHANGES'
                }, {
                    id: 5,
                    title: 'CHECKERS',
                    description: 'FAST AND CONVENIENT TRACKING OF COIN BALANCES ON WALLETS IN MANY EVM NETWORKS'
                }, {
                    id: 6,
                    title: 'REFERRAL',
                    description: 'INVITE FRIENDS AND GET A PORTION OF YOUR SUBSCRIPTION REVENUE MONTHLY'
                }].map((item) => (
                    <Grid item xs={12} sm={6} key={item.id}>
                        <Box sx={{
                            bgcolor: '#4C2FF2',
                            p: 3,
                            borderRadius: 2,
                            boxShadow: 3,
                            position: 'relative'
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                top: '-10px',
                                left: '-10px',
                                bgcolor: '#000',
                                px: 1,
                                py: 0.5,
                                borderRadius: '4px'
                            }}>
                                <Typography variant="caption" sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    fontFamily: 'F37 Atlantic, sans-serif'
                                }}>
                                    [0{item.id}]
                                </Typography>
                            </Box>
                            <Typography variant="h6" sx={{
                                fontWeight: 'bold',
                                mb: 1,
                                color: '#000',
                                fontFamily: 'F37 Atlantic, sans-serif'
                            }}>
                                {item.title}
                            </Typography>
                            <Box sx={{
                                height: '2px',
                                width: '100%',
                                bgcolor: '#000',
                                mb: 1
                            }}></Box>
                            <Typography variant="body2" sx={{
                                color: '#000',
                                fontFamily: 'F37 Atlantic, sans-serif'
                            }}>
                                {item.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default About;
